import copy from "@/assets/languagePack/english";
import store from "@/store";
import AuthState from "@/utils/Auth";
import { engageFhirApi } from "../../services/EngageFhirApi";

export default async (
  convoInitiatorType,
  pagNum,
  perPage,
  status,
  search,
  interactionId,
  sortKey,
  sortDirection
) => {
  const claims = await AuthState.claims();

  const skip = pagNum ? (pagNum - 1) * perPage : 0;

  sortKey =
    sortKey === "DESC" || sortKey === "ASC" ? "created_at" : sortKey ? sortKey : "created_at";

  const payload = {
    filterByOrgIds: AuthState.getOrgId() ? AuthState.getOrgId() : claims.orgIds, // add to webapp api
    searchValue: search ? search : interactionId ? interactionId : undefined,
    communicationType: convoInitiatorType != "direct" ? convoInitiatorType : "conversation",
    status: status ? status : "active",
    skip: skip,
    take: perPage ? perPage : 20,
    sortKey,
    sortDirection: sortDirection ? sortDirection : "DESC"
  };

  if (claims.professionalId) {
    try {
      const results = await engageFhirApi.get("CommunicationRequest", payload);

      store.commit("chatProfessional/totalRecords", results.totalRecords);
      return results;
    } catch (error) {
      const errorDetails = {
        header: copy.api.errors.getActiveConvos,
        message: error
      };
      store.commit("errorHandler/toastableError", errorDetails);
    }
  }
};
