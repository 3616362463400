import axios from "axios";
import AuthState from "@/utils/Auth";

/**
 * A client to the Engage WebApp API that handles authentication and error handling.
 */
export class EngageFhirApiClient {
  constructor() {
    this.axios = axios.create({
      headers: {
        Accept: "application/json"
      }
    });
  }

  /**
   * The constructor runs too early to get the axios base URL from the configuration
   * so call this to get it at request time.
   * @returns The base URL for Engage Webapp FHIR services
   */
  getFhirBaseUrl = () => document.config.engageFhirApiBaseUrl;

  /**
   * Handles errors from the API, logging out the user if the error is an authentication error.
   */
  handleError(err) {
    khanSolo.error("Error from Engage FHIR API", err);
    throw err;
  }

  /**
   * Sends a GET request to the Engage WebApp API.
   */
  async get(urlPath, params) {
    try {
      this.axios.defaults.baseURL = this.getFhirBaseUrl();
      const accessToken = await AuthState.accessToken();
      const idToken = AuthState.getIdToken() || accessToken;
      const response = await this.axios.get(urlPath, {
        headers: { Authorization: `Bearer ${accessToken}`, "id-token": idToken },
        params
      });
      return response.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  /**
   * Sends a POST request to the Engage WebApp API.
   */
  async post(urlPath, payload) {
    try {
      this.axios.defaults.baseURL = this.getFhirBaseUrl();
      const accessToken = await AuthState.accessToken();
      const idToken = AuthState.getIdToken() || accessToken;
      const response = await this.axios.post(urlPath, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "id-token": idToken,
          "Content-Type": "application/json"
        }
      });
      return response.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  /**
   * Sends a PUT request to the Engage WebApp API.
   */
  async put(urlPath, payload) {
    try {
      this.axios.defaults.baseURL = this.getFhirBaseUrl();
      const accessToken = await AuthState.accessToken();
      const idToken = AuthState.getIdToken() || accessToken;
      const response = await this.axios.put(urlPath, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "id-token": idToken,
          "Content-Type": "application/json"
        }
      });
      return response.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  /**
   * Sends a DELETE request to the Engage WebApp API.
   */
  async delete(urlPath) {
    try {
      this.axios.defaults.baseURL = this.getFhirBaseUrl();
      const accessToken = await AuthState.accessToken();
      const idToken = AuthState.getIdToken() || accessToken;
      const response = await this.axios.delete(urlPath, {
        headers: { Authorization: `Bearer ${accessToken}`, "id-token": idToken }
      });
      return response.data;
    } catch (err) {
      this.handleError(err);
    }
  }
}

export const engageFhirApi = new EngageFhirApiClient();
